'use client';

import React, {useState} from 'react';
import Image from 'next/image';
import Link from 'next/link';
import {useRouter} from 'next/navigation';
import {PostWithStore} from '@delorand/db/schema/base';
import Carousel from '@delorand/ui/src/carousel';
import Avatar from '@delorand/ui/src/radix/avatar';
import {ShareSvg} from '@delorand/ui/src/svg/post';
import {HeartSvg} from '@delorand/ui/src/svg/shared';
import {getBaseUrl} from '@delorand/utils/api';
import {CheckBadgeIcon} from '@heroicons/react/24/solid';
import ReactTimeago from 'react-timeago';
import {RWebShare} from 'react-web-share';
import {useStore} from '../../../store/store';
import {Follow} from '../../follow';

type Props = {
  post: PostWithStore;
  index: number;
  onFollow: () => void;
  onLike: () => void;
  userId: number | undefined;
};

const SocialPost = ({post, index, userId, onFollow, onLike}: Props) => {
  const router = useRouter();

  const [liked, setLiked] = useState(false);

  const onAfterAuth = useStore(state => state.onAfterAuth);

  const like = () => {
    if (!userId) {
      onAfterAuth(() => {
        setLiked(x => !x);
        onLike();
      });
      router.push('/login');
    } else {
      setLiked(x => !x);
      onLike();
    }
  };

  return (
    <div>
      <div className="w-full bg-black pb-4">
        {!post.store ? (
          <div className="flex items-center gap-5 bg-black px-3 py-2">
            <div className="size-[30px] rounded-full bg-white p-1.5">
              <Image
                alt="ui/src/svg/logo"
                src={'/android-chrome-192x192.png'}
                width={100}
                height={100}
                className=""
              />
            </div>
            <div className="flex items-center gap-2 text-lg font-semibold">
              <span>Delorand</span>
              <CheckBadgeIcon className="w-4 fill-white" />
            </div>
          </div>
        ) : (
          <div className="flex items-center gap-5 bg-black px-3 py-2">
            <Avatar className="h-[30px] w-[30px] rounded-full" />
            <div className="flex-1">
              <Link
                href={`/stores/${post.store.storeCode}`}
                className="flex items-center text-sm font-semibold"
              >
                {post.store.storeName}
                {post.store.verified && (
                  <span className="ml-2">
                    <CheckBadgeIcon width={16} color="white" />
                  </span>
                )}
              </Link>
            </div>
            <Follow store={post.store} userId={userId} onToggle={onFollow} />
            {/* <button className=''>
          <EllipsisHorizontalIcon width={27} color='white' />
        </button> */}
          </div>
        )}

        <Carousel
          slides={post.medias.map(x => x.url)}
          emblaClassName={'embla-feed'}
          imageWidth={800}
          imageHeight={1080}
          showDots
        />

        <div className="mx-auto flex w-11/12 items-center justify-between pb-1 pt-3">
          <p className="font-medium text-custom-white">
            <ReactTimeago date={post.createdAt} />
          </p>

          <div className="flex items-center gap-4">
            <button onClick={like}>
              <HeartSvg
                width={27}
                fill={liked ? 'red' : 'transparent'}
                stroke={liked ? 'red' : 'white'}
                strokeWidth={1.4}
              />
            </button>

            <RWebShare
              data={{
                text: `delorand post-${post.id}`,
                url: `${getBaseUrl()}/${post.id}`,
                title: 'DELORAND POST',
              }}
            >
              <button>
                <ShareSvg />
              </button>
            </RWebShare>
          </div>
        </div>

        <p className="mb-2 mt-1 px-3 text-[15px]">{post.caption}</p>
      </div>
    </div>
  );
};

export default SocialPost;
