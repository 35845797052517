'use client';

import {ReactNode, useCallback, useEffect, useRef, useState} from 'react';
import useEmblaCarousel, {UseEmblaCarouselType} from 'embla-carousel-react';
import './carousel.css';
import Image from 'next/image';
import * as Slider from './radix/slider';
import {PauseSvg, PlaySvg, VolumeMutedSvg, VolumeSvg} from './svg/post';

type Props = {
  slides: string[];
  onSlideClick?: (index: number) => void;
  options?: Parameters<typeof useEmblaCarousel>[0];
  classNames?: {
    container?: string;
  };
  emblaClassName: string;
  imageWidth?: number;
  imageHeight?: number;
  plugins?: Parameters<typeof useEmblaCarousel>[1] | undefined;
  emblaCarouselReturn?: UseEmblaCarouselType;
  priorityIndexes?: number[];
  showDots?: boolean;
  children?: ReactNode;
  dotsClassName?: string;
};

const Carousel = ({
  slides,
  onSlideClick,
  options,
  classNames,
  emblaClassName,
  imageHeight,
  imageWidth,
  plugins,
  emblaCarouselReturn,
  priorityIndexes = [],
  showDots,
  children,
  dotsClassName,
}: Props) => {
  const defaultHook = useEmblaCarousel(options, plugins);
  const [emblaRef, emblaApi] = emblaCarouselReturn || defaultHook;
  const [tweenValues, setTweenValues] = useState<number[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const onInit = useCallback((emblaApi: any) => {
    setScrollSnaps(emblaApi.scrollSnapList());
  }, []);

  const onSelect = useCallback((emblaApi: any) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, []);

  const scrollTo = useCallback(
    (index: number) => emblaApi && emblaApi.scrollTo(index),
    [emblaApi]
  );

  useEffect(() => {
    if (!emblaApi) return;
    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi.on('reInit', onInit);
    emblaApi.on('reInit', onSelect);
    emblaApi.on('select', onSelect);
  }, [emblaApi, onInit]);

  const isVideo = (url: string) =>
    url.substring(url.lastIndexOf('.') + 1) === 'mp4';

  return (
    <div className={emblaClassName}>
      <div className="embla__viewport" ref={emblaRef}>
        <div className={`embla__container ${classNames?.container || ''}`}>
          {slides.map((url, index) => (
            <div
              className={` ${slides.length < 2 ? 'embla_slide-full' : 'embla__slide'}`}
              key={index}
            >
              <div className="embla__slide__number">
                <span>{index + 1}</span>
              </div>
              <div className="embla__parallax">
                <div
                  className="embla__parallax__layer flex flex-col justify-center"
                  style={{
                    ...(tweenValues.length && {
                      transform: `translateX(${tweenValues[index]}%)`,
                    }),
                  }}
                >
                  {children || (
                    <>
                      {isVideo(url) ? (
                        <VideoPlayer url={url} />
                      ) : (
                        <Image
                          onClick={() => onSlideClick && onSlideClick(index)}
                          className="embla__slide__img embla__parallax__img h-auto w-full bg-black object-cover"
                          src={url}
                          alt="slide"
                          width={imageWidth}
                          height={imageHeight}
                          priority={
                            priorityIndexes.includes(index) || index === 0
                          }
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {showDots && (
        <div className={`embla__dots ${dotsClassName || ''}`}>
          {scrollSnaps.map((_, index) => (
            <button
              key={index}
              onClick={() => scrollTo(index)}
              className={'embla__dot'.concat(
                index === selectedIndex ? 'embla__dot--selected' : ''
              )}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Carousel;

const VideoPlayer = ({url}: {url: string}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [muted, setMuted] = useState(false);

  const [currentTime, setCurrentTime] = useState<number>(0); //current time of the video in seconds
  const [duration, setDuration] = useState<number>(0); //

  useEffect(() => {
    setDuration(videoRef?.current?.duration || 0);
    const interval = setInterval(() => {
      setCurrentTime(videoRef?.current?.currentTime || 0);
    }, 1000);
    return () => clearInterval(interval);
  }, [isPlaying]);

  return (
    <>
      <button
        onClick={() =>
          videoRef.current?.paused
            ? videoRef.current?.play()
            : videoRef.current?.pause()
        }
        className="center-x center-y absolute z-20"
      >
        {!isPlaying ? <PlaySvg /> : <PauseSvg />}
      </button>

      <button
        // disabled={videoRef.current.cu}
        onClick={() => setMuted(state => !state)}
        className="absolute right-4 top-4 z-20 rounded-[10px] bg-black/30 p-1"
      >
        {!muted ? <VolumeSvg /> : <VolumeMutedSvg />}
      </button>

      <video
        id={url}
        muted={muted}
        src={url}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        ref={videoRef}
        loop
        playsInline
        disablePictureInPicture
        disableRemotePlayback
        className="h-full w-full"
      />
      <div
        className={`absolute inset-x-0 bottom-2 z-20 opacity-100 transition-all hover:opacity-100`}
      >
        <p className="mx-auto mb-4 w-fit rounded-lg bg-white/20 px-3 py-[3px] text-xs font-medium">
          {Math.floor(currentTime / 60)}:{Math.floor(currentTime)} /{' '}
          {Math.floor(duration / 60)}:{Math.floor(duration)}
        </p>
        <Slider.Root
          onValueChange={(value: number[]) => {
            if (videoRef.current) {
              videoRef.current.currentTime = value[0]!;
            }
          }}
          className="relative mx-auto mb-2 flex w-9/12 touch-none select-none items-center"
          defaultValue={[0]}
          value={[currentTime]}
          min={0}
          max={duration}
          step={0.5}
          aria-label="seek"
        >
          <Slider.Track className="relative h-[5px] w-full rounded-sm bg-white/50">
            <Slider.Range className="bg-red absolute h-full" />
          </Slider.Track>
          <Slider.Thumb className="block h-[19px] w-[19px] rounded-[5px] bg-white outline-none" />
        </Slider.Root>
      </div>
    </>
  );
};
