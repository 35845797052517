import useSWRInfinite from 'swr/infinite';
import {client} from '../../../server/api';

const getKey = (pageIndex: number, previousPageData: any) => {
  if (pageIndex === 0)
    return JSON.stringify({
      index: 1,
    });
  if (!previousPageData && pageIndex !== 0) return null; // reached the end
  return JSON.stringify({
    index: pageIndex + 1,
    rank: previousPageData.rank,
  }); // SWR key
};

export const useFeed = () => {
  const res = useSWRInfinite(
    getKey,
    async key => {
      const params = JSON.parse(key);

      return await client.base.feed.query({
        rank: params.rank,
        index: params.index,
      });
    },
    {
      initialSize: 0,
      revalidateFirstPage: false,
      revalidateAll: false,
    }
  );

  return res;
};
