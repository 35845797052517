import Image from 'next/image';
import {Feed} from '@delorand/api/helpers/feed';
import {formatCurrency, getThumbnail, getVariant} from '@delorand/utils/shared';
import Countdown from 'react-countdown';

function PromotionSection({
  promotion,
  index,
}: {
  promotion: Feed['promotions'][0];
  index: number;
}) {
  return (
    <>
      {promotion.type === 'IMAGE' ? (
        <Image
          alt="promotion"
          src={promotion.image || ''}
          className="w-full object-contain"
          width={500}
          height={600}
          priority={index < 2}
        />
      ) : (
        <div className="w-full border-b border-t pb-1">
          <div
            className={`${promotion.countdown ? 'bg-white text-black' : ''} p-1`}
          >
            <h3 className="ml-6 text-xl font-semibold">
              {promotion.title || 'Deals'}
            </h3>
            {promotion.countdown && (
              <Countdown
                date={promotion.countdown}
                autoStart
                precision={3}
                renderer={({days, hours, minutes}) => (
                  <h4 className="text-center text-[18px] font-semibold">
                    {days} day
                    {days > 1 && 's'}, {hours} hours, {minutes} minutes left
                  </h4>
                )}
              />
            )}
          </div>

          <div className="thin-scrollbar mt-2 flex w-full gap-3 overflow-x-auto px-2.5 pb-3">
            {promotion.promotionProducts.map(({product}) => {
              // const product = promotionProduct.;
              return (
                <div key={product.id}>
                  <div className="flex w-[200px] flex-col">
                    <Image
                      alt="product"
                      src={getThumbnail(product.images)}
                      width={200}
                      height={200}
                      className="aspect-square flex-1 rounded-[10px]"
                      priority={index < 2}
                    />
                    <div className="mt-2">
                      <h4 className="text-center">{product.title}</h4>
                      <p className="text-center text-[17px] font-medium">
                        {formatCurrency(getVariant(product.variants).price)}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}

export default PromotionSection;
